import { PrismicRichText } from "@prismicio/react";
import { Button } from "@/components";
import { PrismicNextImage } from "@prismicio/next";
import { useRef, useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import useIsomorphicLayoutEffect from "@/Utility/useIsomorphicLayoutEffect";

import Styles from "./ServiceTwoColumn.module.css";

/**
 * @typedef {import("@prismicio/client").Content.ServiceTwoColumnSlice} ServiceTwoColumnSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ServiceTwoColumnSlice>} ServiceTwoColumnProps
 * @param {ServiceTwoColumnProps}
 */
const ServiceTwoColumn = ({ slice: { primary } }) => {
  const image = useRef();
  const [imageOffset, setImageOffset] = useState();
  const { width } = useWindowSize();

  useIsomorphicLayoutEffect(() => {
    if (width <= 768) {
      setImageOffset(image.current.offsetHeight);
      return;
    } else if (width > 768 && width < 1024) {
      setImageOffset(image.current.offsetHeight - 100);
      return;
    } else {
      setImageOffset(image.current.offsetHeight - 200);
    }
  }, [image, width]);

  return (
    <section className={Styles.wrapper}>
      <div className={Styles.container}>
        <div className={Styles.col}>
          <PrismicRichText field={primary?.left_heading} />
          <PrismicRichText field={primary?.left_description} />
          <Button
            variant="primary"
            label={primary?.left_cta_label}
            link={primary?.left_cta_link}
          />
        </div>
        <div
          className={Styles.col}
          style={{ paddingBottom: `${imageOffset}px` }}
        >
          <PrismicRichText field={primary?.right_heading} />
          <PrismicRichText field={primary?.right_description} />
          <Button
            variant="primary"
            label={primary?.right_cta_label}
            link={primary?.right_cta_link}
          />
        </div>
      </div>
      <div ref={image} className={Styles.imageContainer}>
        <PrismicNextImage
          field={primary?.background_image}
          className={Styles.image}
        />
      </div>
    </section>
  );
};

export default ServiceTwoColumn;
